/* CONTATO */

.duvidas {
  padding-top: 4rem;
  background-color: var(--cdm-principal);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.duvidas-vendas {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.margin-duvidas {
  margin: 1.5rem 0;
}

.bg-duvidas-title {
  width: 100%;
  background-color: var(--cdm-secundaria);
  padding: 2rem 0;
}

.titulo-duvidas h2 {
  margin: 0 2rem;
}

.titulo-duvidas {
  color: var(--cdm-branco);
  font-size: 1.5rem;
  font-weight: 700;
  text-decoration: underline;
  text-underline-offset: 0.3rem;
  line-height: 2rem;
  text-decoration-color: var(--cdm-principal);
  text-align: center;
}

.vendas-text {
  color: var(--cdm-branco);
  line-height: 1.5;
  font-weight: 500;
  margin: 0 2rem;
  text-align: center;
}

.vendas-atendimento-email {
  text-decoration: underline;
  text-decoration-color: var(--cdm-secundaria);
  color: var(--cdm-secundaria);
  font-weight: 700;
}

/* BOTÕES */

.canais-vendas-container {
  margin: 2rem;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.canais-vendas {
  display: flex;
  justify-content: center;
  max-width: 25rem;
}

.canais-vendas-buttons {
  display: block;
  text-decoration: none;
  color: var(--cdm-secundaria);
  font-size: 1rem;
  font-weight: 600;
  width: 100%;
  padding: 1rem;
  border: 0.1rem solid var(--cdm-secundaria);
  border-radius: 0.7rem;
}

.canais-vendas-buttons img {
  width: 40px;
  margin-bottom: 0.2rem;
}

.canais-vendas-buttons:hover {
  transform: scale(1);
  color: var(--cdm-branco);
  background-color: var(--cdm-secundaria);
  border: 0.1rem solid var(--cdm-branco);
}

@media screen and (min-width: 768px) {
  .duvidas {
    padding-top: 4.9rem;
  }

  .vendas-text {
    margin: 0 5rem;
  }

  .titulo-duvidas h2 {
    font-size: 1.7rem;
    text-underline-offset: 0.4rem;
    line-height: 2rem;
    padding-top: 0.5rem;
  }
}

@media screen and (min-width: 1025px) {
  .duvidas {
    padding-top: 9.5rem;
  }

  .vendas-text {
    margin: 0 10rem;
  }

  .titulo-duvidas h2 {
    font-size: 1.8rem;
    text-underline-offset: 0.4rem;
    line-height: 2rem;
    padding-top: 0.5rem;
  }
}

@media screen and (min-width: 1440px) {
  .duvidas {
    padding-top: 10.5rem;
  }

  .vendas-text {
    margin: 0 40rem;
  }

  .titulo-duvidas h2 {
    font-size: 2.3rem;
    text-underline-offset: 0.5rem;
    line-height: 2rem;
    padding-top: 1rem;
  }
}
