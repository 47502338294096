/* Estilos Mobile First */

/* Container Principal */
.politica-privacidade-container {
    padding: 6rem 2rem;
    max-width: 100%;
    margin: 0 auto;
  }
  
  /* Conteúdo */
  .politica-privacidade-content {
    font-family: Arial, sans-serif;
  }
  
  .politica-privacidade-title {
    font-size: 20px;
    color: #333;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .politica-privacidade-date {
    text-align: center;
    color: #777;
    font-size: 12px;
    margin-bottom: 20px;
  }
  
  .politica-privacidade-subtitle {
    font-size: 18px;
    color: #333;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .politica-privacidade-text {
    font-size: 14px;
    color: #555;
    line-height: 1.6;
  }
  
  .politica-privacidade-list {
    margin-left: 20px;
    font-size: 14px;
    color: #555;
    list-style-type: disc;
    line-height: 1.4;
  }
  
  .politica-privacidade-link {
    color: #007bff;
    text-decoration: none;
  }
  
  .politica-privacidade-link:hover {
    text-decoration: underline;
  }
  
  /* Estilos para telas maiores (tablets e desktops) */
  
  /* Tablets (max-width: 768px) */
  @media (min-width: 768px) {

    .politica-privacidade-container {
        padding: 7rem 6rem;
      }

    .politica-privacidade-title {
      font-size: 24px;
    }
  
    .politica-privacidade-date {
      font-size: 14px;
    }
  
    .politica-privacidade-subtitle {
      font-size: 20px;
    }
  
    .politica-privacidade-text,
    .politica-privacidade-list {
      font-size: 16px;
    }
  
  }
  
  /* Desktop (min-width: 1024px) */
  @media (min-width: 1024px) {
    .politica-privacidade-title {
      font-size: 28px;
    }
  
    .politica-privacidade-subtitle {
      font-size: 22px;
    }
  
    .politica-privacidade-text,
    .politica-privacidade-list {
      font-size: 18px;
    }
  
    .politica-privacidade-container {
      max-width: 800px;
        padding: 12rem 6rem;
    }
  }
  