.telemed-page {
    padding-top: 4rem;
    text-align: center;
    background-color: var(--cdm-secundaria);
    height: 92vh;
}

.telemed-page__img {
    width: 100%;
}

.telemed-page h1 {
    display: none;
    font-size: 1.5rem;
    font-weight: 700;
}

.telemed-page p {
    line-height: 1.3;
}

.telemed-page h1,
.telemed-page p {
    color: var(--cdm-branco);
    margin-top: 2rem;
    padding: 0 1.5rem;
}

.contato__telemed {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    gap: 2rem;
}

.contato__telemed-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contato__telemed-container p {
    font-weight: 700;
    margin-top: 0.4rem;
}

.contato__telemed-container h4 {
    color: var(--cdm-principal);
    margin-top: 0.3rem;
    font-weight: 700;
}

.contato__telemed-container img {
    width: 40px;
    margin-bottom: 0.5rem;
}

.telemed-page_button button {
    border: 2px solid var(--cdm-branco);
    background-color: var(--cdm-principal);
    color: var(--cdm-branco);
    font-weight: 700;
    border-radius: 5px;
    margin-top: 2rem;
    padding: 0.7rem 1.4rem;
    font-size: 1rem;
    cursor: pointer;
}

.telemed-page_button button:hover {
    background-color: var(--cdm-cinza);
}

@media screen and (min-width: 768px) {
    .telemed-page {
        padding-top: 5rem;
    }

    .telemed-page h1 {
        display: block;
        font-size: 1.7rem;
    }

    .telemed-page p {
        line-height: 1.3;
    }

    .telemed-page h1,
    .telemed-page p {
        padding: 0 5rem;
        display: none;
    }

    .contato__telemed {
        display: none;
    }
}

@media screen and (min-width: 1025px) {
    .telemed-page {
        padding-top: 10rem;
    }
}

@media screen and (min-width: 1440px) {
    .telemed-page {
        padding: 10.8rem 0 0;
        height: auto;
    }
}