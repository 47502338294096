/* Estilos comuns */
.wpp__button {
  position: fixed;
  z-index: 1000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 7rem;
  right: 1rem;
}

.wpp-link {
  color: var(--cdm-preto);
  text-decoration: none;
  display: flex;
  align-items: center;
}

.wpp-icon {
  width: 3.5rem;
  height: auto;
  margin-left: 0.2rem;
  animation: pulse 1s infinite;
}

.wpp__button:hover .wpp-icon {
  transform: scale(1.1);
  transition: transform 0.3s ease;
}

/* Estilos do balão de texto */
.wpp-balloon {
  background-color: var(--cdm-form-confirmation);
  color: white;
  padding: 8px 15px;
  border-radius: 15px;
  margin-left: 10px;
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
  position: relative;
}

.wpp-balloon::after {
  content: '';
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent var(--cdm-form-confirmation) transparent;
}

/* Estilos para telas maiores */
@media screen and (min-width: 768px) {
  .wpp__button {
    bottom: 6rem;
  }

  .wpp__button a {
    font-size: 1rem;
  }

  .wpp-icon {
    width: 4rem;
    margin-left: 0.5rem;
  }
}

@media screen and (min-width: 1024px) {
  .wpp-icon {
    width: 4rem;
    margin-left: 0.6rem;
  }
}

/* Animação de pulsação */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
