.lgpd-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8); /* Sombreamento no fundo */
    display: flex;
    justify-content: center;
    align-items: flex-end; /* Alinha o modal no bottom */
    z-index: 10000;
}

.lgpd-modal {
    background: white; /* Ou altere para vermelho: background: #dc3545; */
    padding: 20px;
    border-radius: 8px 8px 0 0; /* Apenas bordas superiores arredondadas */
    text-align: center;
    max-width: 600px;
    width: 90%;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.3);
}

.lgpd-modal p {
    font-size: 16px;
    color: #333;
}

.lgpd-modal a {
    color: #007bff;
    text-decoration: none;
}

.lgpd-buttons {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    gap: 10px;
}

.lgpd-buttons button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    border-radius: 5px;
    transition: background 0.3s;
}

.accept {
    background-color: var(--cdm-principal);
    color: white;
}

.accept:hover {
    background-color: #c82333;
}

.decline {
    background-color: #e7e7e7;
    color: var(--cdm-secundaria);
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease; 
}

.decline:hover {
    background-color: #acacac; 
    color: var(--cdm-secundaria);
}


/* Responsivo */
@media (max-width: 480px) {
    .lgpd-modal {
        width: 90%;
        font-size: 14px;
        padding: 15px;
    }

    .lgpd-buttons {
        flex-direction: column;
    }

    .lgpd-buttons button {
        width: 100%;
    }
}
